<template>
  <div v-if="!isLoading" :class="{'has-background-grey': !isActive, 'has-background-danger': isBlocked && isActive, 'has-background-success': !isBlocked && isActive}" style="width: 100%; height: 100%; min-height: 100vh; padding-top: 20px">
    <div style="max-width:500px; max-height:400px;margin:auto">
      <div class="has-text-centered pt-6">
        <div v-if="workplace.length < 1">
          <h1 class="title is-2 white">Error</h1>
          <span class="white">Dieser Arbeitsplatz scheint nicht zu existieren.</span>
        </div>
        <div v-else>
          <h1 class="white title is-2 mb-1">{{workplace.title}}</h1>
          <span class="white">{{workplace.description}}</span><br>
          <b-tag type="is-primary" size="is-medium" style="background-color:white;color:black;margin-bottom:10px;margin-top:20px">Dieser Arbeitsplatz ist {{ isActive? (isBlocked ? 'belegt' : 'frei') : 'inaktiv'}}</b-tag>
          <div v-if="timeSinceCheckIn !== 0 && isBlocked && isActive" class="mt-3">
            <span class="white title is-4">{{timeSinceCheckIn !== 0 ? timeSinceCheckIn : ''}}</span>
          </div>
          <b-field v-if="!isBlocked" label="Bitte geben Sie Ihren Namen ein:" class="mx-6">
            <b-input v-model="name" type="is-white" custom-class="has-text-centered"></b-input>
          </b-field>
          <br>
          <b-button v-if="isActive" class="mt-3" @click="changeBlocked()" :loading="loading">{{isBlocked ? 'Check out' : 'Check in'}}</b-button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import { setDoc, doc, serverTimestamp, updateDoc, onSnapshot } from 'firebase/firestore'
import { db, auth } from '../firebase'
import { onAuthStateChanged, signInAnonymously } from '@firebase/auth'
import { nanoid } from 'nanoid'

export default {
  data () {
    return {
      workplace: [],
      isBlocked: true,
      isActive: true,
      name: '',
      userId: '',
      loading: false,
      now: new Date(),
      isLoading: true,
      isFullPage: true
    }
  },
  methods: {
    async changeBlocked () {
      if ((this.name !== '' && !this.isBlocked) || this.isBlocked) {
        this.loading = true
        localStorage.setItem('Name', this.name)
        const nanoId = nanoid()
        const checkInsOutsRef = doc(db, 'workplace', this.documentId, 'checkInsOuts', nanoId)
        const workplaceRef = doc(db, 'workplace', this.documentId)
        if (this.isBlocked) {
          console.log('1')
          await setDoc(checkInsOutsRef, {
            name: this.name,
            timestamp: serverTimestamp(),
            userId: this.userId,
            status: 'check-out'
          })
          console.log('2')
          await new Promise(res => setTimeout(()=>res(),2000))
          await updateDoc(workplaceRef, {
            timestamp: serverTimestamp(),
            blocked: false,
            lastActionId: nanoId
          })
        } else {
          await setDoc(checkInsOutsRef, {
            name: this.name,
            timestamp: serverTimestamp(),
            userId: this.userId,
            status: 'check-in'
          })
          await updateDoc(workplaceRef, {
            timestamp: serverTimestamp(),
            blocked: true,
            lastActionId: nanoId
          })
        }
        this.loading = false
      } else {
        this.$buefy.toast.open('Bitte Namen eingeben')
      }
    }
  },
  computed: {
    documentId () {
      return this.$route.params.documentId
    },
    timeSinceCheckIn () {
      if (this.workplace.blocked &&  this.workplace.timestamp !== null && typeof this.workplace.timestamp !== 'undefined') {
        let time = this.now.getTime() - this.workplace.timestamp.toDate().getTime()
        if (isNaN(time) || time < 0) {
          time = 0
        } 
        const seconds = Math.floor((time / 1000) % 60)
        const minutes = Math.floor((time / (1000 * 60)) % 60)
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24)
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      } else {
        return 0
      }
    }
  },
  async created () {
    await signInAnonymously(auth)
    onAuthStateChanged(auth, async (user) => {
      onSnapshot(doc(db, 'workplace', this.documentId), (doc) => {
        if (typeof doc.data() === 'undefined') {
          this.isLoading = false
        } else {
          this.workplace = doc.data()
          this.isBlocked = this.workplace.blocked
          this.isActive = this.workplace.active
          this.isLoading = false
        }
      })
      this.userId = user.uid
      this.name = localStorage.getItem('Name') || ''
    })
  },
  mounted () {
    setInterval (
      () => { this.now = new Date() },
      1000
    )
  }
}
</script>

<style>
.white, label {
  color: white!important;;
}
</style>
